import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const SheathTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },

  { text: "作業者", value: "userId" },
  { text: "受注番号", value: "acceptNo" },
  { text: "仕様書No", value: "specificationNo" },
  { text: "下出", value: "groundwork" },
  { text: "品名", value: "itemName" },
  { text: "サイズ", value: "size" },
  { text: "押出数量(m)", value: "extrusionQuantity" },
  { text: "数量明細(m)", value: "quantityDetails" },
  { text: "押出外径(mm)", value: "outerDiameter" },
  { text: "ニップル径(mm)", value: "nippleDiameter" },
  { text: "ダイス径(mm)", value: "diceDiameter" },
  { text: "白線", value: "isWhiteLine" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class SheathTable {
  workDate: string;
  updateTs: string | null;
  userId: string | null;
  acceptNo: string | null;
  specificationNo: string | null;
  groundwork: boolean;
  itemName: string | null;
  size: string | null;
  extrusionQuantity: string | null;
  quantityDetails: string | null;
  outerDiameter: number | null;
  nippleDiameter: number | null;
  diceDiameter: number | null;
  isWhiteLine: boolean;
  abnormality: string | null;
  biko: string | null;
  isLinkage: string | null;
  denno: number | null;
  gyono: number | null;

  constructor(props: {
    workDate: string;
    userId: string | null;
    acceptNo: string | null;
    specificationNo: string | null;
    groundwork: string | null;
    itemName: string | null;
    size: string | null;
    extrusionQuantity: string | null;
    quantityDetails: string | null;
    outerDiameter: number | null;
    nippleDiameter: number | null;
    diceDiameter: number | null;
    isWhiteLine: string | null;
    abnormality: string | null;
    biko: string | null;
    isLinkage: string | null;
    denno: number | null;
    gyono: number | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.userId = props.userId;
    this.acceptNo = props.acceptNo;
    this.specificationNo = props.specificationNo;
    this.groundwork = props?.groundwork === "1" ? true : false;
    this.itemName = props.itemName;
    this.size = props.size;
    this.extrusionQuantity = props.extrusionQuantity;
    this.quantityDetails = props.quantityDetails;
    this.outerDiameter = props.outerDiameter;
    this.nippleDiameter = props.nippleDiameter;
    this.diceDiameter = props.diceDiameter;
    this.isWhiteLine = props.isWhiteLine === "1" ? true : false;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
    this.isLinkage = props.isLinkage;
    this.denno = props.denno;
    this.gyono = props.gyono;
  }
}

export const SheathText = {
  workDate: { text: "作業年月日", value: "workDate" },
  machineCode: { text: "機械名", value: "machineCode" },
  userId: { text: "作業者", value: "userId" },
  inspectionFlag: { text: "機械点検記録", value: "inspectionFlag" },
  acceptNo: { text: "受注番号", value: "acceptNo" },
  successFlag: { text: "受注番号品 完了", value: "successFlag" },
  specificationNo: { text: "仕様書No", value: "specificationNo" },
  groundwork: { text: "下出", value: "groundwork" },
  itemName: { text: "品名", value: "itemName" },
  size: { text: "サイズ", value: "size" },
  extrusionQuantity: { text: "押出数量(m)", value: "extrusionQuantity" },
  quantityDetails: { text: "数量明細(m)", value: "quantityDetails" },
  outerDiameter: { text: "押出外径(mm)", value: "outerDiameter" },
  nippleDiameter: { text: "ニップル径(mm)", value: "nippleDiameter" },
  diceDiameter: { text: "ダイス径(mm)", value: "diceDiameter" },
  isThicknessDeviation: { text: "偏肉確認", value: "isThicknessDeviation" },
  isWhiteLine: { text: "白線", value: "isWhiteLine" },
  isMarkingConfirm: { text: "マーキング表示確認", value: "isMarkingConfirm" },
  insertTape: { text: "挿入表示テープ", value: "insertTape" },
  eMark: { text: "<PS>Eマーク", value: "eMark" },
  coatingLotNo1: { text: "被覆材量/ロットNo.(1)", value: "coatingLotNo1" },
  coatingLotNo2: { text: "被覆材量/ロットNo.(2)", value: "coatingLotNo2" },
  coatingLotNo3: { text: "被覆材量/ロットNo.(3)", value: "coatingLotNo3" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class Sheath extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  userId: string | null;
  inspectionFlag: string | null;
  acceptNo: string | null;
  successFlag: string | null;
  specificationNo: string | null;
  groundwork: string | null;
  itemName: string | null;
  size: string | null;
  extrusionQuantity: string | null;
  quantityDetails: string | null;
  outerDiameter: number | null;
  nippleDiameter: number | null;
  diceDiameter: number | null;
  isThicknessDeviation: string | null;
  isWhiteLine: string | null;
  isMarkingConfirm: string | null;
  insertTape: string | null;
  eMark: string | null;
  coatingLotNo1: string | null;
  coatingLotNo2: string | null;
  coatingLotNo3: string | null;
  abnormality: string | null;
  biko: string | null;
  isLinkage: string | null;
  denno: number | null;
  gyono: number | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: Sheath);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: Sheath | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof Sheath) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.userId = _props.userId;
      this.inspectionFlag = _props.inspectionFlag;
      this.acceptNo = _props.acceptNo;
      this.successFlag = _props.successFlag;
      this.specificationNo = _props.specificationNo;
      this.groundwork = _props.groundwork;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.extrusionQuantity = _props.extrusionQuantity;
      this.quantityDetails = _props.quantityDetails;
      this.outerDiameter = _props.outerDiameter;
      this.nippleDiameter = _props.nippleDiameter;
      this.diceDiameter = _props.diceDiameter;
      this.isThicknessDeviation = _props.isThicknessDeviation;
      this.isWhiteLine = _props.isWhiteLine;
      this.isMarkingConfirm = _props.isMarkingConfirm;
      this.insertTape = _props.insertTape;
      this.eMark = _props.eMark;
      this.coatingLotNo1 = _props.coatingLotNo1;
      this.coatingLotNo2 = _props.coatingLotNo2;
      this.coatingLotNo3 = _props.coatingLotNo3;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.isLinkage = _props.isLinkage;
      this.denno = _props.denno;
      this.gyono = _props.gyono;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "SHEATH";
    this.machineCode = null;
    this.userId = null;
    this.inspectionFlag = "0";
    this.acceptNo = null;
    this.successFlag = "0";
    this.specificationNo = null;
    this.groundwork = "0";
    this.itemName = null;
    this.size = null;
    this.extrusionQuantity = null;
    this.quantityDetails = null;
    this.outerDiameter = null;
    this.nippleDiameter = null;
    this.diceDiameter = null;
    this.isThicknessDeviation = "0";
    this.isWhiteLine = "0";
    this.isMarkingConfirm = "0";
    this.insertTape = null;
    this.eMark = null;
    this.coatingLotNo1 = null;
    this.coatingLotNo2 = null;
    this.coatingLotNo3 = null;
    this.abnormality = null;
    this.biko = null;
    this.isLinkage = "0";
    this.denno = null;
    this.gyono = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
